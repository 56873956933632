  /* tslint:disable: ordered-imports*/
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/* Modules */

/* Components */
import * as navigationComponents from './components';

/* Containers */
import * as navigationContainers from './containers';

/* Layouts */
import * as appCommonLayouts from './layouts';

/* Guards */
import * as navigationGuards from './guards';


/* Services */
import * as navigationServices from './services';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IconsModule} from '../../../modules/icons/icons.module';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LoadingModule } from '../loading/loading.module';
import {ListboxModule} from "primeng/listbox";
import {MenuModule} from "primeng/menu";
  import {MatBadgeModule} from "@angular/material/badge";

  @NgModule({
      imports: [
          CommonModule,
          RouterModule,
          FontAwesomeModule,
          IconsModule,
          NgbDropdownModule,
          ButtonModule,
          RippleModule,
          ConfirmDialogModule,
          LoadingModule,
          ListboxModule,
          MenuModule,
          MatBadgeModule
      ],
    providers: [...navigationServices.services, ...navigationGuards.guards],
    declarations: [
        ...navigationContainers.containers,
        ...navigationComponents.components,
        ...appCommonLayouts.layouts,
    ],
    exports: [
        ...navigationContainers.containers,
        ...navigationComponents.components,
        ...appCommonLayouts.layouts,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class NavigationModule {}
