import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { ProgramInputModel, ModuleInputModel } from "../models/admin/program.model";

@Injectable({
  providedIn: 'root'
})
export class ProgramasService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  public createPrograma(data: ModuleInputModel) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.auth.url}admin/programas`, data, {
          headers: { Authorization: `Bearer ${this.auth.userToken}` },
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  public listProgramas() {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.auth.url}admin/programas`,  {
          headers: { Authorization: `Bearer ${this.auth.userToken}` },
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  public deleteProgram(id: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.auth.url}admin/deleteProgram`, 
        id, {
          headers: { Authorization: `Bearer ${this.auth.userToken}` },
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  public deleteSubProgram(id: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.auth.url}admin/deleteSubProgram`, 
        id, {
          headers: { Authorization: `Bearer ${this.auth.userToken}` },
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  public addSubProgram(data: ProgramInputModel) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.auth.url}admin/addSubProgram`, 
        data, {
          headers: { Authorization: `Bearer ${this.auth.userToken}` },
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  public modifyProgram(data: ModuleInputModel) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.auth.url}admin/modifyProgram`, 
        data, {
          headers: { Authorization: `Bearer ${this.auth.userToken}` },
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  public modifySubProgram(data: ProgramInputModel) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.auth.url}admin/modifySubProgram`, 
        data, {
          headers: { Authorization: `Bearer ${this.auth.userToken}` },
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  
  public getProgramsByIdUser(data: any){
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.auth.url}admin/getProgramsByIdUser`, 
        data, {
          headers: { Authorization: `Bearer ${this.auth.userToken}` },
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }
}
