import {Component, Input, OnInit} from '@angular/core';
import {NavigationService} from '../../services';
import { AuthService } from "../../../../../services/auth.service";
import {NotificacionModel} from "../../../../../models/notificaciones/notificacion.model";


@Component({
    selector: 'sb-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
    constructor(private navigationService: NavigationService, private authService: AuthService) {}

    @Input() ventana!: string;
    mostrarNotificaciones = false;

    async ngOnInit(): Promise<any> {

        console.log(this.ventana);

        if(localStorage.getItem('user') == null){
            const result: any = await this.authService.getUser();
            localStorage.setItem('user', result.nombre);

        }

        if(localStorage.getItem('isAdmin') == null){
            const result: any = await this.authService.getUser();
            localStorage.setItem('isAdmin', result.root);
        }

        if(localStorage.getItem('id')== null){
            const result: any =  await this.authService.getUser();
            localStorage.setItem('id', result.id);
        }


    }


    toggleSideNav() {
        this.navigationService.toggleSideNav();
    }


    // Obtenemos el nombre de la ventana en la que nos encontramos
    getVentana(){
        return localStorage.getItem('ventana');
    }





    nombre = localStorage.getItem('user').split(' ');
    user = localStorage.getItem('id') +' - '+ this.nombre[0];
}
