/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faBars,
  faBookOpen,
  faChartArea,
  faChartBar,
  faChartPie,
  faChevronDown,
  faChevronUp, faCog, faCogs,
  faColumns,
  faSearch,
  faTable,
  faTachometerAlt,
  faUser,
  faBuilding, faMobileAlt, faMobile, faServer, faLink, faPager,faTablet,faTabletAlt, faBell, faCircle, faBook, faFileInvoice, faTag,
  faPlusCircle, faTrash, faUsers, faFingerprint,
  faScrewdriver,
  faTrashAlt,
  faPlus, faChevronRight, faFolder, faWindowRestore, faLock, faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import {faConnectdevelop} from '@fortawesome/free-brands-svg-icons';

export const fontAwesomeSolidIcons = {
    faAngleDown,
    faBell,
    faCircle,
    faAngleRight,
    faArrowLeft,
    faBars,
    faBookOpen,
    faBook,
    faFileInvoice,
    faTag,
    faPlusCircle,
    faTrash,
    faUsers,
    faFingerprint,
    faChartArea,
    faChartBar,
    faChartPie,
    faChevronDown,
    faChevronUp,
    faColumns,
    faSearch,
    faTable,
    faTachometerAlt,
    faUser,
    faCog,
    faCogs,
    faBuilding,
    faMobileAlt,
    faMobile,
    faServer,
    faConnectdevelop,
    faLink,
    faPager,
    faTabletAlt,
    faTablet,
    faScrewdriver,
    faTrashAlt,
    faPlus,
    faChevronRight,
    faFolder,
    faWindowRestore,
    faLock,
    faPencilAlt
};



