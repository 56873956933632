import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ActivationComponent } from './components/pages/activation/activation.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NavigationModule} from "./components/shared/navigation/navigation.module";
import {DropdownModule} from "primeng/dropdown";
import {LoadingModule} from "./components/shared/loading/loading.module";
import {FormsModule} from "@angular/forms";
import {FileUploadModule} from "primeng/fileupload";
import {CalendarModule} from "primeng/calendar";
import {MatInputModule} from "@angular/material/input";
import {ListboxModule} from "primeng/listbox";

@NgModule({
    declarations: [
        AppComponent,
        ActivationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FontAwesomeModule,
        NgbModule,
        NavigationModule,
        DropdownModule,
        LoadingModule,
        FormsModule,
        FileUploadModule,
        CalendarModule,
        MatInputModule,
        ListboxModule,
    ],
    providers: [],
    bootstrap: [AppComponent],

})
export class AppModule { }
