import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ProgramasService } from "../../services/programas.service";

@Injectable({
  providedIn: 'root'
})
export class  PerfilGuard implements CanActivate {
  
  constructor( private router: Router, private programasService: ProgramasService) {}

  async canActivate( route: ActivatedRouteSnapshot ) {
    
    if(localStorage.getItem("isAdmin") == "1") {
      return true;
  } 

    const result: any = await this.programasService.getProgramsByIdUser({
      id: localStorage.getItem("user_id_auth")
    })

    if(result.data.find((program: any) => program.url == `/${route.routeConfig.path}`)) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }


  
}
