<nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
  <a class="navbar-brand" routerLink="/home" style="text-align: left">
    <img src="assets/img/logo.png" alt="logo"  style="width: 50px" />
  </a>
  <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle"
    data-cy="topNavToggleSideNav" (click)="toggleSideNav()">
    <fa-icon [icon]="['fas', 'bars']"></fa-icon>
  </button><!-- Navbar Search-->

  <!-- Nombre de la ventana -->
  <div class="titulo-ventana">
    {{getVentana()}}
  </div>


  <!--Información del usuario-->
  <div class="info-user d-flex">
    <fa-icon [icon]="['fas','user']"></fa-icon>
    <div class="user">{{ this.user }}</div>
  </div>


  <!-- Notificaciones -->
  <div class="notificaciones ">

    <!-- Botón de acción -->
    <button class="btn btn-link btn-sm order-1 order-lg-0" id="btn-notificaciones" (click)="mostrarNotificaciones = !mostrarNotificaciones">
      <fa-icon [icon]="['fas', 'bell']" style="color: white" matBadge="15" matBadgeColor="warn"></fa-icon>
    </button>

    <!--Componente que mostrara las notificaciones-->
    <lista-notificaciones [ventana]="ventana" [mostarNotificaciones]="mostrarNotificaciones"></lista-notificaciones>

  </div>

  <!-- Navbar-->
  <ul class="navbar-nav ml-auto ml-md-0">
    <sb-top-nav-user></sb-top-nav-user>
  </ul>
</nav>
