import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class ControlNotificacionesService {
    constructor(private http: HttpClient , private auth: AuthService) { }

    // Obtiene las control-notificaciones que ha dado de alta el cliente
    getNotificaciones(): any{
        let array = [];
        return this.http.get<[]>(
            `${this.auth.url}notificaciones/getNotificaciones`,
            { headers: { 'Authorization': `Bearer ${this.auth.userToken}` } },
        ).pipe(
            map(
                resp => {
                    array = resp;
                    return array;
                }
            )
        );
    }

    // Obtiene las control-notificaciones dependiendo del tipo
    getNotificacionesTipo(data):any{
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.auth.url}notificaciones/getNotificacionesTipo`,
                    data,{
                        headers: { Authorization: `Bearer ${this.auth.userToken}` },
                    })
                .subscribe({
                    next: (data) => resolve(data),
                    error: (err) => reject(err),
                });
        });
    }

    //Insertar una notificación
    insertNotificaciones(data): any{
        let authData = {
            ...data
        };
        let array = [];
        return this.http.post<[]>(
            `${this.auth.url}notificaciones/insertarNotificacion`, authData ,
            { headers: { 'Authorization': `Bearer ${this.auth.userToken}` } },
        ).pipe(
            map(
                resp => {
                    array = resp;
                    return array;
                }
            )
        );
    }

    //Editar una notificación
    editarNotificaciones(data): any{
        let authData = {
            ...data
        };
        let array = [];
        return this.http.post<[]>(
            `${this.auth.url}notificaciones/updateNotificacion`, authData ,
            { headers: { 'Authorization': `Bearer ${this.auth.userToken}` } },
        ).pipe(
            map(
                resp => {
                    array = resp;
                    return array;
                }
            )
        );
    }

    // Obtiene los usuarios dentro de megaro
    getUsuarios(): any{
        let array = [];
        return this.http.get<[]>(
            `${this.auth.url}notificaciones/getUsuarios`,
            { headers: { 'Authorization': `Bearer ${this.auth.userToken}` } },
        ).pipe(
            map(
                resp => {
                    array = resp;
                    return array;
                }
            )
        );
    }

    // Obtiene las ventanasdentro de megaro
    getVentanas(): any{
        let array = [];
        return this.http.get<[]>(
            `${this.auth.url}notificaciones/getVentanas`,
            { headers: { 'Authorization': `Bearer ${this.auth.userToken}` } },
        ).pipe(
            map(
                resp => {
                    array = resp;
                    return array;
                }
            )
        );
    }

    // Borra la notificación
    borrarNotificacion(data): any{

        let authData = {
            ...data
        };

        let array = [];
        return this.http.post<[]>(
            `${this.auth.url}notificaciones/borrarNotificacion`, authData ,
            { headers: { 'Authorization': `Bearer ${this.auth.userToken}` } },
        ).pipe(
            map(
                resp => {
                    array = resp;
                    return array;
                }
            )
        );
    }

    // Retirar notificación de la pantalla
    retirarNotificacion(data): any{

        let authData = {
            ...data
        };

        console.log(data);

        let array = [];
        return this.http.post<[]>(
            `${this.auth.url}notificaciones/retirarNotificacion`, authData ,
            { headers: { 'Authorization': `Bearer ${this.auth.userToken}` } },
        ).pipe(
            map(
                resp => {
                    array = resp;
                    return array;
                }
            )
        );
    }


}
