<div id="loading">
  <div>
    <p-progressSpinner
      [style]="{ width: '80px', height: '80px' }"
      styleClass="custom-spinner"
      strokeWidth="5"
      animationDuration="1s"
    ></p-progressSpinner>

    <div>Cargando información, por favor espere...</div>
  </div>
  <div class="bg"></div>
</div>
