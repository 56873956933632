import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { NavigationService } from '../../services';
import { SideNavItems, SideNavSection } from '../../models';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../../../../services/auth.service";
import {NotificacionModel} from "../../../../../models/notificaciones/notificacion.model";


@Component({
    selector: 'sb-layout-dashboard',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './layout-dashboard.component.html',
    styleUrls: ['layout-dashboard.component.scss'],
})
export class LayoutDashboardComponent implements OnInit, OnDestroy {
    @Input() static = false;
    @Input() light = false;
    @Input() ventana!: string;
    @HostBinding('class.sb-sidenav-toggled') sideNavHidden = true;
    @HostBinding('class.sb-sidenav-toggled') notificacionesHidden = false;
    subscription: Subscription = new Subscription();
    sideNavSections: SideNavSection[];
    sideNavItems: SideNavItems = {};
    notificaciones: NotificacionModel[];

    sidenavStyle = 'sb-sidenav-dark';
    loading = true;

    constructor(public navigationService: NavigationService, private changeDetectorRef: ChangeDetectorRef, private router: Router, private http: HttpClient,
                private auth: AuthService) {

    }

    async ngOnInit() {

        console.log(this.ventana);

        if (localStorage.getItem("isAdmin") == '1') {

            await this.getProgramasAdmin();

        } else {
            await this.getProgramas();

        }

        if (this.light) {
            this.sidenavStyle = 'sb-sidenav-light';
        }
        this.subscription.add(
            this.navigationService.sideNavVisible$().subscribe(isVisible => {
                this.sideNavHidden = this.router.url == '/home' ? isVisible : !isVisible;
                this.changeDetectorRef.markForCheck();

            })
        );

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private async getProgramas() {
        let sideNavData: SideNavSection[] = [];
        let sideNavItems: SideNavItems = {};

        const result: any = await this.navigationService.listProgramas({ id: localStorage.getItem("user_id_auth") });

        result.data.map((dataItem) => {
            //Agregamos modulos
            if (!sideNavData.find((item) => (item.items[0] == dataItem.nombre))) {

                sideNavData.push({
                    items: [dataItem.nombre]
                });

                sideNavItems[dataItem.nombre] = {
                    icon: dataItem.icono,
                    text: dataItem.nombre,
                    submenu: []
                }

            }

            sideNavItems[dataItem.nombre].submenu.push({
                icon: dataItem.icono_item,
                text: dataItem.nombre_item,
                link: dataItem.url_item
            });

        });

        if (localStorage.getItem('perfil') === "1") {
            sideNavData.push({
                items: ['Administración']
            });

            sideNavItems['Administración'] = {
                icon: 'folder',
                text: 'Administración',
                submenu: [
                    {
                        icon: 'users',
                        text: 'Usuarios',
                        link: '/administracion/usuarios',
                    },
                    {
                        icon: 'lock',
                        text: 'Roles y permisos',
                        link: '/administracion/roles-permisos',
                    },
                    {
                        icon: 'window-restore',
                        text: 'Control de programas',
                        link: '/administracion/ventanas',
                    },
                    {
                        icon: 'bell',
                        text: 'Control de notificaciones',
                        link: '/administracion/control-notificaciones',
                    },
                    {
                        icon: 'bell',
                        text: 'Administración de credenciales servidores',
                        link: '/administracion/administrar-credencial-servidores',
                    }
                ],
            };
        }

        this.sideNavSections = sideNavData;
        this.sideNavItems = sideNavItems;
        this.loading = false;

    }

    private async getProgramasAdmin() {
        let sideNavData: SideNavSection[] = [];
        let sideNavItems: SideNavItems = {};

        const result: any = await this.navigationService.listProgramasAdmin({ id: localStorage.getItem("user_id_auth") });

        result.data.map((dataItem) => {
            //Agregamos modulos
            if (!sideNavData.find((item) => (item.items[0] == dataItem.nombre))) {

                sideNavData.push({
                    items: [dataItem.nombre]
                });

                sideNavItems[dataItem.nombre] = {
                    icon: dataItem.icono,
                    text: dataItem.nombre,
                    submenu: []
                };

            }

            sideNavItems[dataItem.nombre].submenu.push({
                icon: dataItem.icono_item,
                text: dataItem.nombre_item,
                link: dataItem.url_item
            });

        });

        if (localStorage.getItem('perfil') === "1") {
            sideNavData.push({
                items: ['Administración']
            })

            sideNavItems['Administración'] = {
                icon: 'folder',
                text: 'Administración',
                submenu: [
                    {
                        icon: 'users',
                        text: 'Usuarios',
                        link: '/administracion/usuarios',
                    },
                    {
                        icon: 'lock',
                        text: 'Roles y permisos',
                        link: '/administracion/roles-permisos',
                    },
                    {
                        icon: 'window-restore',
                        text: 'Control de programas',
                        link: '/administracion/ventanas',
                    },
                    {
                        icon: 'bell',
                        text: 'Control de notificaciones',
                        link: '/administracion/control-notificaciones',
                    },
                    {
                        icon: 'bell',
                        text: 'Administración de credenciales servidores',
                        link: '/administracion/administrar-credencial-servidores',
                    }
                ],
            };
        }

        this.sideNavSections = sideNavData;
        this.sideNavItems = sideNavItems;
        this.loading = false;

    }



}
