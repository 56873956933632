import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {

  tittle: string;
  text: string;
  button: boolean;
  buttonText: string;
  uri: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {


  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.tittle = decodeURIComponent(escape((atob(params['titulo']))));
        this.text =  decodeURIComponent(escape((atob(params['text']))));
        this.button = (params['button'] !== undefined );
        this.buttonText = params['buttonText'] === undefined ?  '' : decodeURIComponent(escape((atob(params['buttonText']))));
        this.uri = params['uri'] === undefined ?  '#' : decodeURIComponent(escape((atob(params['uri']))));
      }
    );
  }

  async accion() {
    return new Promise(resolve => {
      setTimeout(() => {
        if (this.uri !== '#'){
          this.router.navigate(['activation'], {queryParams: { titulo: btoa(' Cuenta activada') ,
              text: btoa('Tu cuenta ha sido activada exitosamente.'),
              button: btoa('1'), buttonText: btoa(unescape(encodeURIComponent('Iniciar sesión')))}});
        }
      }, 2000);
    });
  }

}
