<!--Lista de notificaciones-->
<div *ngIf="mostarNotificaciones" >
    <p-listbox [options]="notificaciones" [listStyle]="{'background': 'none'}" class="{{(mostarNotificaciones)? 'mostrarNotificacion':''}}"
               [listStyle]="{'width':'21rem'}">
        <ng-template pTemplate="header">
           <div  class="header-notificaciones">
               Notificaciones
           </div>
        </ng-template>
        <ng-template let-notificacion pTemplate="item">
            <div *ngIf="notificaciones.length > 0" class="d-flex justify-content-between w-100">
                <div class="notificacion d-flex w-100 ">
                    <div class="icono mt-2 mr-4">
                        <fa-icon [icon]="['fas', 'bell']"></fa-icon>
                    </div>
                   <div class="info d-flex flex-column">
                       <div class="mensaje">{{notificacion.mensaje}}</div>
                       <div class="tiempo">Hace {{conversionTiempo(notificacion.creacion)}}</div>
                   </div>
                </div>

                <div class="accion">
                    <button class="btn btn-link" id="btn-notificaciones" (click)="retirarNotificacion(notificacion.id_not)" >
                        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
           <div *ngIf="notificaciones.length == 0" class="sin-notificaciones">
              Sin notificaciones
           </div>
            <div *ngIf="notificaciones.length > 0" class="sin-notificaciones">
            </div>
        </ng-template>
    </p-listbox>
</div>



