import {ChangeDetectionStrategy, Component, HostListener, Input, OnInit} from '@angular/core';
import {NotificacionModel} from "../../../../../models/notificaciones/notificacion.model";
import {NavigationService} from "../../services";
import {interval, Subscription} from "rxjs";
import {ControlNotificacionesService} from "../../../../../services/control-notificaciones.service";


@Component({
  selector: 'lista-notificaciones',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {

  identificadorRepetir;
  notificaciones: NotificacionModel[];
  suscripcion: Subscription;
  @Input() mostarNotificaciones!: boolean;
  @Input() ventana!: string;

  //Se inicia un bucle para traer las notificaciones cada segundo
  //idTimer: any = setInterval(() => this.getNotificaciones(), 1500);

  constructor(private navigationService: NavigationService, private noticacionesService: ControlNotificacionesService) { }

  async ngOnInit(): Promise<any> {
    await this.getNotificaciones();
  }

  // Retirar notificación de la pantalla
  async retirarNotificacion(id): Promise<any>{
    const datos = {id_not: id};
    this.noticacionesService.retirarNotificacion(datos).subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);
    });
    await this.getNotificaciones();
  }


  // Obtiene la notificaciones asociadas
  async getNotificaciones(): Promise<any>{
    const datos = { id_usuario: localStorage.getItem('id'),
                    link_ventana: this.ventana};
    const result = await this.navigationService.getNotificacionesTipo(datos);
    let notificacionesData: NotificacionModel[] = [];

    result.forEach( info => {
      if(!notificacionesData.find( (notificacion) => { info.id == notificacion.id ? true : false })){
        notificacionesData.push(info);
      };
    });

    this.notificaciones = notificacionesData;
  }

  // Función que convierte el dato tiempo a periodos
  conversionTiempo(tiempo: number): string{
    let resultado = '';
    let aux = 0;
    let auxTime = false;

    // Años
    if ( tiempo >= (365 * 24 * 60 * 60) ){
      aux = Math.round(tiempo / (365 * 24 * 60 * 60));
      resultado = resultado + ((aux.toString().length >= 1 && aux != 1)? aux+' años ': ((aux == 1)? aux+' año ':''));
      tiempo = tiempo - ( aux * (365 * 24 * 60 * 60));
    }

    // Meses
    if (tiempo >= (30 * 24 * 60 * 60) ){
      aux = Math.floor(tiempo / (30 * 24 * 60 * 60));
      resultado = resultado + ((aux.toString().length >= 1 && aux != 1) ? aux + ' meses ' : ((aux == 1)? aux + ' mes ':''));
      tiempo = tiempo % (30 * 24 * 60 * 60);
    }

    // Días
    if (tiempo >= (24 * 60 * 60) ){
      aux = Math.floor(tiempo / ( 24 * 60 * 60));
      resultado = resultado + ((aux.toString().length >= 1 && aux != 1) ? aux + ' días ' : ((aux == 1)? aux + ' día ':''));
      tiempo = tiempo % (24 * 60 * 60);
    }

    auxTime = (resultado.length == 0) ? true : false;

    if( auxTime ){
      // Horas
      if (tiempo >= (60 * 60) ){
        aux = Math.floor(tiempo / (60 * 60));
        resultado = resultado + ((aux.toString().length >= 1 && aux != 1) ? aux + ' horas ' : ((aux == 1)? aux + ' hora ':''));
        tiempo = tiempo % (60 * 60);
      }

      // Minutos
      if (tiempo >= 60 ){
        aux = Math.floor(tiempo / (60));
        resultado = resultado + ((aux.toString().length >= 1 && aux != 1) ? aux + ' minutos ' : ((aux == 1)? aux + ' minuto ':''));
        tiempo = tiempo % 60;
      }

      auxTime = (resultado.length == 0) ? true : false;

      if (auxTime){
        // Segundos restantes
        if (tiempo < 59 ){
          resultado = resultado + ((tiempo.toString().length >= 1 && tiempo != 1) ? tiempo + ' segundos ' : ((tiempo == 1)? tiempo + ' segundo ':''));
        }
      }

    }
    return resultado;
  }
}
