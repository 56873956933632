<span [ngClass]="{ 'sb-nav-fixed': !static }"
  ><sb-top-nav [ventana]="ventana" ></sb-top-nav>
  <div id="layoutSidenav" *ngIf="!loading">
    <div
      id="layoutSidenav_nav"
      class="{{ sideNavHidden ? 'layoutSidenav_nav_hidden' : '' }}"
      
    >
      <sb-side-nav
        [sidenavStyle]="sidenavStyle"
        [sideNavItems]="sideNavItems"
        [sideNavSections]="sideNavSections"
      ></sb-side-nav>
    </div>
    
    <div
      id="layoutSidenav_content"
      class="{{ sideNavHidden || loading ? 'layoutSidenav_content_hidden' : '' }} {{
        sideNavHidden ? '' : 'layoutSidenav_content_show'
      }}"
    >
      <main>
        <div class="container-fluid"><ng-content></ng-content></div>
      </main>
    </div>
  </div>
</span>

<app-loading *ngIf="loading"></app-loading>
