<nav
  class="sb-sidenav accordion scrollbar-primary"
  id="sidenavAccordion"
  [ngClass]="sidenavStyle"
>
  <div class="sb-sidenav-menu">
    <div class="nav" style="font-size: .75rem">
      <ng-container *ngFor="let section of sideNavSections">
        <div class="sb-sidenav-menu-heading" *ngIf="section.text">
          {{ section.text }}
        </div>
        <ng-container *ngFor="let item of section.items"
          ><sb-side-nav-item
            [sideNavItem]="sideNavItems[item]"
          ></sb-side-nav-item></ng-container
      ></ng-container>
    </div>
  </div>
</nav>
