import { Injectable } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SBRouteData } from '../models';
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../../../services/auth.service";

@Injectable()
export class NavigationService {
    _sideNavVisible$ = new BehaviorSubject(true);
    _routeData$ = new BehaviorSubject({} as SBRouteData);
    _currentURL$ = new BehaviorSubject('');
    _notificacionesVisible$ = new BehaviorSubject(true);

    constructor(public route: ActivatedRoute, public router: Router, private http: HttpClient, private auth: AuthService) {
        this.router.events
            .pipe(filter(event => event instanceof ChildActivationEnd))
            .subscribe(event => {
                let snapshot = (event as ChildActivationEnd).snapshot;
                while (snapshot.firstChild !== null) {
                    snapshot = snapshot.firstChild;
                }
                this._routeData$.next(snapshot.data as SBRouteData);
                this._currentURL$.next(router.url);
            });
    }

    sideNavVisible$(): Observable<boolean> {
        return this._sideNavVisible$;
    }

    toggleSideNav(visibility?: boolean) {
        if (typeof visibility !== 'undefined') {
            this._sideNavVisible$.next(visibility);
        } else {
            this._sideNavVisible$.next(!this._sideNavVisible$.value);
        }
    }

    routeData$(): Observable<SBRouteData> {
        return this._routeData$;
    }

    currentURL$(): Observable<string> {
        return this._currentURL$;
    }

    public listProgramas(data: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.auth.url}admin/getModulesByIdUser`, 
                data,{
                    headers: { Authorization: `Bearer ${this.auth.userToken}` },
                })
                .subscribe({
                    next: (data) => resolve(data),
                    error: (err) => reject(err),
                });
        });
    }

    public listProgramasAdmin(data: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.auth.url}admin/getModulesMegaroAll`, 
                data,{
                    headers: { Authorization: `Bearer ${this.auth.userToken}` },
                })
                .subscribe({
                    next: (data) => resolve(data),
                    error: (err) => reject(err),
                });
        });
    }


    /*** FUNCIONES PARA LAS NOTIFICACIONES ***/

    notificacionesVisible$(): Observable<boolean>{
        return this._notificacionesVisible$;
    }

    mostarNotificaciones(visibility?: boolean): void {
        if (typeof visibility !== 'undefined') {
            this._notificacionesVisible$.next(visibility);
        } else {
            this._notificacionesVisible$.next(!this._notificacionesVisible$.value);
        }
    }

    public getNotificacionesTipo(data: any): Promise<any>{
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.auth.url}notificaciones/getNotificacionesTipo`,
                    data,{
                        headers: { Authorization: `Bearer ${this.auth.userToken}` },
                    })
                .subscribe({
                    next: (data) => resolve(data),
                    error: (err) => reject(err),
                });
        });
    }



}
