import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../../services/auth.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';



@Component({
  selector: 'sb-top-nav-user',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './top-nav-user.component.html',
  styleUrls: ['top-nav-user.component.scss'],
  providers: [ConfirmationService],
})
export class TopNavUserComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router, private confirmationService: ConfirmationService) { }
  ngOnInit() { }

  salir(){
    this.confirmationService.confirm({
      message: '¿Seguro que quiere salir?',
      header: 'Cerrar Sesión',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.logOut();
      }
    });
  }

  logOut(): void {
    this.auth.logOut()
      .subscribe(resp => {
        if (resp['message'] === 'sesion_cerrada') {
          this.router.navigateByUrl('/login');
        }
      }, (err) => {
        console.log(err);
      });

  }
}
